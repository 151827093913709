@import '../../../Mixins.scss';

.desktopHeader {
  padding: 24px;
  position: fixed;
  pointer-events: none;
  .backButton {
    pointer-events: all;
  }
}
