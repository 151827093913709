@import '../../../../../Mixins.scss';

.campaignInfoPanel {
  background-color: #ffffff;
  border-radius: 18px;
  gap: 24px;
  justify-content: start;

  @include desktop {
    padding: 28px;
    overflow-y: auto;
  }

  @include tabletLandscape {
    padding: 24px;
    overflow-y: auto;
  }

  @include tabletPortrait {
    padding: 24px;
    overflow-y: auto;
  }

  @include mobile {
    position: relative;
    height: 100%;
    padding: 0px 20px;
    gap: 16px;
  }

  & > div:has(+ .campaignModalButtons) {
    @include mobile {
      height: 200%;
    }
  }

  .campaignInfoButton {
    align-self: center;
  }
}
