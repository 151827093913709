@import '../../Mixins.scss';

.reportingDashboard {
  align-items: flex-start;
  gap: 24px;
  background: var(--brand-primary-color);

  .reportTitle {
    display: grid;
    grid-template-columns: 5fr 1fr;
    grid-template-areas: 'titleHeading titleOptions';
    width: -webkit-fill-available;
    width: -moz-available;

    @include mobile {
      grid-template-columns: 1fr;
      grid-template-areas: 'titleHeading' 'titleOptions';
      padding: 0px 20px;
      gap: 16px;
    }

    .reportTitleHeading {
      grid-area: titleHeading;
      gap: 16px;

      :not(:first-child) {
        opacity: 0.5;
      }
    }

    .reportTitleOptions {
      grid-area: titleOptions;
      gap: 20px;

      @include mobile {
        margin-right: auto;
      }
    }
  }

  .entityTypeSelector {
    min-width: 180px;
  }

  .reportMainBody {
    padding: 24px;
    gap: 40px;
    display: grid;
    grid-template-columns: 2fr 3fr;
    grid-template-areas: 'leaderboard dashboard';
    width: -webkit-fill-available;
    width: -moz-available;
    border-radius: 20px;
    background: var(--brand-inbox-background-color);

    @include mobile {
      grid-template-areas: 'dashboard' 'leaderboard';
      grid-template-columns: 1fr;
      flex-direction: column;
      gap: 20px;
    }

    @include tabletPortrait {
      gap: 20px;
    }

    @include tabletLandscape {
      padding: 20px;
      gap: 20px;
    }

    .reportCardSection {
      grid-area: dashboard;
      gap: 10px;

      @include mobile {
        gap: 10px;
      }

      .reportCardTitle {
        display: flex;
        flex-direction: row;
        width: 100%;

        .dashboardGoals {
          margin-left: auto;
          border: none;
          background: none;
        }
      }

      .reportCards {
        grid-area: dashboard;
        width: 100%;
        gap: 10px;

        display: grid;
        grid-template-columns: 1fr 1fr;

        @include mobile {
          grid-template-columns: 1fr;
        }

        h1.header {
          font-weight: 300;
        }
      }
    }

    @include mobile {
      grid-template-areas: 'dashboard' 'leaderboard';
      grid-template-columns: 1fr;
    }

    @include tabletPortrait {
      grid-template-areas: 'dashboard' 'leaderboard';
      grid-template-columns: auto;
    }
  }
}

.noDataDiv {
  padding: 51px 0px;
}

.value-placeholder {
  height: 20px; /* Or whatever the height of your .value div is */
  visibility: hidden; /* Makes the placeholder invisible but still takes up space */
}
