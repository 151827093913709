@import '../../../../../../../Mixins.scss';

.signatory {
  position: relative;
  display: grid;

  grid-template-columns: 40px 4fr 1fr;
  align-items: center;

  width: -webkit-fill-available;
  width: -moz-available;
  gap: 8px;

  @include desktop {
    padding: 12px 0px;
  }

  @include mobile {
    padding: 10px 0px;
  }

  @include tabletLandscape {
    padding: 10px 0px;
  }

  @include tabletPortrait {
    padding: 10px 0px;
  }

  .signatoryName {
    line-height: 125%;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .signatoryIconWrapper {
    width: 32px;
    height: 32px;
  }

  .signatoryContentWrapper {
    width: 100%;
    overflow: hidden;
  }

  .signatoryDetails {
    line-height: 125%;
  }

  .moreOptionsButton {
    cursor: pointer;
  }
  .disabled-div {
    pointer-events: none;
    color: #aaa;
  }
  .moreOptionsContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    position: absolute;
    top: 60%;
    right: 0;
    width: 134px;
    /* Neutral/Grey 100 */
    background: #fafafa;
    /* Shadow/Card Shadow */
    box-shadow:
      0px 4px 8px rgba(0, 0, 0, 0.05),
      0px 1px 2px rgba(0, 0, 0, 0.1);
    z-index: 1;

    div {
      width: -webkit-fill-available;
      width: -moz-available;
      cursor: pointer;
      padding: 16px 0 16px 16px;
    }
  }
}

.resendModalStyle {
  padding: 16px 40px;

  .strong {
    font-weight: 500;
  }
}
