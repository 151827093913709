.commissionPercentage {
  display: flex;
  align-self: flex-end;
  padding: 0 !important;
}

.errorMessage {
  color: var(--ui-error);
}

.descriptionStyle {
  & > span {
    color: var(--neutral-grey-600);
    font-weight: 500;
  }

  & > svg {
    fill: var(--neutral-grey-600);
  }

  & > svg > path {
    fill: var(--neutral-grey-600);
  }
}
