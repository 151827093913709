.upcomingSettlementsBody {
  gap: 25px;
}

.upcomingSettlements {
  border-radius: 16px;
  background: var(--UrbanX-White, #fff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);

  .upcomingSettlementRow {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr;
    column-gap: 16px;

    padding: 12px 16px;

    &.header {
      padding: 16px 16px;

      > div > .body {
        font-weight: 400;
      }
    }

    .upcomingSettlement {
      &.commission {
        text-align: right;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--Transparent-10-Black, rgba(0, 0, 0, 0.1));
    }

    &.anniversary {
      background: var(--Neutral-Grey-200, #f5f5f5);
    }
  }
}
