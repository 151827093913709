/* Applying Brand Colors */
:root {
  --brand-primary-color: #f5f5f5;
  --brand-primary-color-rgb: 27, 46, 70;
  --brand-secondary-color: #cfa987;
  --brand-secondary-color-rgb: rgb(207, 169, 135);
  --brand-tertiary-color: rgba(255, 255, 255, 0.1);
  --brand-text-primary-color: #ffffff;
  --brand-text-primary-color-rgb: 71, 72, 71;
  --brand-text-button-color: #ffffff;
  --brand-notification-bubble-color: #fff;
  --brand-transparentA: rgba(46, 43, 43, 0.115);
  --brand-transparentB: rgba(255, 255, 255, 0.1);
  --brand-sidebar-background-color: rgba(255, 255, 255, 0.3);
  --brand-inbox-background-color: rgba(255, 255, 255, 0.3);

  --urbanx-black: #000000;
  --ui-error: #f94a3f;
  --ui-success: #47BA00;

  --form-optional-color: #989898;
  --form-input-border: #cdcdcd;

  --form-padding-horz-mobile: 20px;
  --form-padding-horz-desktop: 40px;
  --form-padding: 20px;

  --neutral-grey-900: #444444;
}

.brandPrimaryColour {
  fill: var(--brand-primary-color);
}

.brandSecondaryColour {
  fill: var(--brand-secondary-color);
}

html,
body,
#root {
  min-height: 100vh;
  min-height: 100dvh;
  -webkit-font-smoothing: antialiased;
  margin: 0;
  padding: 0;
  background: var(--brand-primary-color);
}

.pac-container {
  background: var(--brand-primary-color);
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.pac-item,
.pac-matched,
.pac-item-query {
  border: 0;
  margin: 0px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 150%;

  letter-spacing: 0.06px;

  color: var(--brand-text-primary-color);
}

.pac-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pac-item:not(:first-child) {
  margin-top: 16px;
}

/* Reset */
p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

.cursorPointer:hover,
.cursorPointer *:hover {
  cursor: pointer;
}

#launcher-frame {
  right: 20px !important;
  bottom: 70px !important;
}
