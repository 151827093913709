@import '../../../../../../../Mixins.scss';

.downloadButtonStyle {
    color: var(--brand-secondary-color) !important;
}

.fileListItem {
    padding: 12px;
    border-radius: 8px;
    border: 1px solid #DFDFDF;
    max-width: 500px;
}

.fileListItemWithoutBorder {
    border: none;
    max-width: 500px;
}