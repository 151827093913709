@import '../../../../../Mixins.scss';

.campaignTabsContainer {
  width: -webkit-fill-available;
  width: -moz-available;
  border-bottom: 1px solid var(--neutral-grey-300);
}

.campaignsTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.campaignsTab.selected {
  font-weight: 500;
  margin-bottom: -1px;
  border-bottom: 1px solid var(--urbanx-black);
}

.campaignsTab:hover {
  margin-bottom: -1px;
  border-bottom: 1px solid var(--urbanx-black);
}
