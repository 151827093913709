.fileSelector {
  > button {
    align-self: flex-start;
  }

  .uploadedFile {
    display: grid;

    grid-template-columns: 52px 1fr 20px;
    grid-template-areas: 'icon name removeButton';
    align-items: center;

    border: 1px solid var(--neutral-grey-300);
    border-radius: 8px;
    padding: 4px;
    gap: 8px;

    &.uploading {
      .fileIcon {
        > svg {
          animation: rotation 4s infinite linear;
        }
      }
    }

    .fileIcon {
      grid-area: icon;
      width: 52px;
      height: 52px;
      background: var(--neutral-grey-300);
      border-radius: 4px;
    }

    .fileOptionsSelector {
      width: fit-content;
      cursor: pointer;

      .agx-selectedValueLabel {
        font-size: 14px;
        &.default {
          color: var(--form-optional-color);
        }
      }
      .agx-dropdownIcon {
        padding-left: 6px;
        &.default {
          path {
            fill: var(--form-optional-color);
          }
        }
      }

      .fileOptions {
        position: absolute;
        cursor: pointer;
        background-color: white;
        margin-top: 4px;
        box-shadow:
          0px 4px 8px rgba(0, 0, 0, 0.05),
          0px 1px 2px rgba(0, 0, 0, 0.1);

        .agx-selectedValue {
          padding: 12px;
        }
      }
    }

    .fileDetails {
      grid-area: name;
      overflow: hidden;

      .fileName {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .fileSize {
        white-space: nowrap;
      }
    }

    .removeButton {
      grid-area: removeButton;
      cursor: pointer;
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }

  .fileProgressIndicator {
    width: 100%;
    height: 3px;
    background: var(--neutral-grey-300);
    border-radius: 5px;

    .currentProgress {
      background: var(--neutral-grey-900);
      border-radius: 5px;
      height: 3px;
      -webkit-transition: width 0.25s ease;
      -moz-transition: width 0.25s ease;
      -ms-transition: width 0.25s ease;
      -o-transition: width 0.25s ease;
      transition: width 0.25s ease;
    }
  }

  .uploadProgress {
    min-width: 40px;
  }
}

input[type='file'] {
  display: none;
}

.error {
  color: var(--ui-error);
}
