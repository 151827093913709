.agx-minMaxCommission {
  display: grid;
  grid-template-areas: 'min nothing max';
  grid-template-columns: 1fr 20px 1fr;
  width: -webkit-fill-available;
  width: -moz-available;
  align-items: baseline;
  gap: 10px;
}

.rangeTextStyle {
  position: relative;
  top: 35px;
}

.min {
  grid-area: min;
}

.max {
  grid-area: max;
}
