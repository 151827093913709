@import '../../../Mixins.scss';

.goals-modal.modal-open {
  outline: none;
}

.goals-modal-style {
  background: var(--UrbanX-White, #fff);
  border-radius: 18px;
  position: relative;
  margin: 40px;

  @include mobile {
    margin: 0;
  }

  .modal-close-button {
    display: flex;
    width: 25px;
    height: 25px;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    top: 20px;
  }

  .modal-close-button button {
    background: none;
    border: none;
    cursor: pointer;
  }

  .goalsUpper {
    padding: 32px 40px;
    gap: 16px;
  }

  .goal-inputs {
    gap: 16px;

    @include mobile {
      flex-direction: column;
    }
  }

  .values-container {
    gap: 16px;

    .value-item {
      gap: 6px;
      flex: 1 0 0;
    }
  }

  .goalsButtons {
    padding: 32px 20px;
    gap: 10px;
  }
}

.ReactModal__Overlay {
  background: rgba(27, 46, 70, 0.5) !important;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}
