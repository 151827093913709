@import '../../../Mixins.scss';

.signatory-row {
  margin: 0 -40px;

  @include mobile {
    margin: 0 -20px;
  }

  .signatory-content {
    padding: 0 40px;

    @include mobile {
      padding: 0 20px;
    }

    &.column.veryLargeSidePadding {
      padding: 0 40px;
    }

    .extraLargePadding {
      padding: 24px 0;
    }

    > .row > svg {
      width: 38px !important;
      height: 25px !important;
    }
  }
}

.signatory-content {
  > .row > svg {
    width: 38px !important;
    height: 25px !important;
  }
}

.signatory-row-information {
  margin: 0 0 20px;
}

.signatory-row-buttons {
  margin: -24px -40px -32px;

  @include mobile {
    margin: -24px -20px -32px;
  }
}

.borderBottom {
  border-bottom: 1px solid var(--neutral-grey-300);
}

.borderTop {
  border-top: 1px solid var(--neutral-grey-300);
}

.alignColumn {
  align-self: center;
}

.agreementDetails.column.extraLargePadding {
  gap: 24px;
  padding-top: 8px;
  padding-bottom: 40px;
}

.emailGrid {
  padding-top: 20px;
  margin: 0 -40px -8px;
}

.fitContent {
  min-width: fit-content;
}
