@import '../../App.scss';
@import '../../Mixins.scss';

.hide {
  display: none !important;
}

.hide + div.divider {
  display: none !important;
}

.formBackground {
  @include desktop {
    &:not(.noBreadcrumbs) {
      background-image: linear-gradient(
          to bottom,
          rgba(var(--brand-primary-color-rgb), 1),
          rgba(var(--brand-primary-color-rgb), 0.2)
        ),
        linear-gradient(
          to right,
          rgba(var(--brand-primary-color-rgb), 1),
          rgba(var(--brand-primary-color-rgb), 0.2)
        ),
        url('../../assets/images/white-map-bg.png');
      background-size: cover;
    }
  }

  min-height: 100vh;

  @include mobile {
    min-width: 100vw;
  }
}

.agxFormPage {
  display: grid;

  grid-template-columns: 1fr 2fr;
  grid-template-areas: 'desktopSidebar formContent';
  grid-template-rows: minmax(max-content, 90vh);
  justify-items: center;

  @include mobile {
    display: flex;
    padding-top: 20px;
    align-items: flex-start;
  }

  @include desktop {
    padding: 0px 24px 24px 24px;
  }

  @include tabletLandscape {
    padding: 0px 24px 24px 24px;
  }

  @include tabletPortrait {
    display: flex;
    padding-top: 20px;
    align-items: flex-start;
  }

  &.noBreadcrumbs {
    grid-template-columns: 1fr;
    grid-template-areas: 'formContent';

    @include desktop {
      align-items: center;
    }
  }
}

.formContent {
  grid-area: formContent;
  display: flex;
  flex-direction: column;
  gap: 20px;

  @include desktop {
    width: 660px;
    padding-top: 95px;
  }

  @include tabletLandscape {
    width: 660px;
    padding-top: 95px;
  }

  @include tabletPortrait {
    width: 100vw;
    width: 100dvw;
  }

  @include mobile {
    width: 100vw;
    width: 100dvw;
  }
}

.agx-form-elements {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 18px;
  width: -webkit-fill-available;
  width: -moz-available;

  &.noGap {
    gap: 0;
  }

  &.growContent {
    flex-grow: 1;
  }
}

.agx-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  width: -webkit-fill-available;

  background: #ffffff;

  @include mobile {
    flex-grow: 1;
    padding: 24px 20px;
    border-radius: 24px 24px 0px 0px;
    min-height: calc(100vh - 190.48px);
    min-height: calc(100dvh - 190.48px);
    gap: 24px;

    &.inverted {
      padding: 0 20px;
    }
  }

  @include tabletPortrait {
    flex-grow: 1;
    padding: 24px 20px;
    border-radius: 24px 24px 0px 0px;
    min-height: calc(100vh - 200px);
    min-height: calc(100dvh - 200px);
    gap: 24px;
    margin: 0 32px;

    &.inverted {
      padding: 0 20px;
    }
  }

  @include desktop {
    padding: 32px 40px;
    border-radius: 24px;
  }

  @include tabletLandscape {
    padding: 32px 40px;
    border-radius: 24px;
  }

  &.inverted {
    background: initial;

    @include desktop {
      padding: 0;
    }

    > .agx-form-elements {
      padding: 0;
    }
  }
}

.noBreadcrumbs .agx-form {
  @include mobile {
    min-height: calc(100vh - 68.48px);
    min-height: calc(100dvh - 68.48px);
  }
}

.agx-form-internal {
  width: -webkit-fill-available;
  width: -moz-available;
}

.agx-form-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fill-available;
  width: -moz-available;

  @include mobile {
    padding: 0px 20px;
  }

  @include desktop {
    padding: 24px 24px 0px 24px;
  }

  @include tabletLandscape {
    padding: 24px 24px 0px 24px;
  }

  @include tabletPortrait {
    padding: 0px 20px;
  }

  & > .button {
    @include mobile {
      flex: 1;
    }
  }
}
