@import '../../../Mixins.scss';

.manualComparable {
  gap: 20px;

  .manualComparableButtons {
    > button {
      text-transform: uppercase;
      font-weight: 500;
      font-size: 12px;
      line-height: 165%;
      letter-spacing: 0.06px;

      svg {
        height: auto;
        width: auto;
      }
    }
  }
}

.manualComparableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  align-items: center;

  > .row {
    gap: 4px;
  }

  button {
    svg {
      width: 20px;
      height: 20px;
      fill: var(--neutral-grey-500);
    }
  }

  .comparableDistance {
    text-align: end;
  }

  @include mobile {
    grid-template-columns: 1fr 1fr;
  }
}

.manualComparableAddress {
  display: grid;
  gap: 10px;
  grid-template-columns: 2fr 2fr;
  align-items: center;

  grid-template-areas: 'addressSearch price';

  @include mobile {
    grid-template-columns: 1fr;

    grid-template-areas:
      'addressSearch'
      'price';
  }

  .addressDropdown {
    grid-area: addressSearch;

    .wrapper {
      padding: 9px 8px;
    }
  }

  .soldPrice {
    grid-area: price;
  }
}

.manualComparableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 2fr;
  gap: 10px;

  @include mobile {
    grid-template-columns: 1fr 1fr;
  }

  .alignedRight {
    text-align: end;
  }
}
