.contractCard {
  max-width: 500px;
  align-self: center;
  border-radius: 16px;
  background: var(--neutral-grey-100);
  border: 1px solid var(--neutral-grey-300);
  padding: 8px;

  > .propertyImage {
    width: 70px;
    height: 54px;

    &.archived {
      filter: grayscale(100%);
    }
  }

  &:hover {
    cursor: pointer;
    background: var(--neutral-grey-200);
  }
}
