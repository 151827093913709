@import '../../../Mixins.scss';

.authentication-error {
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  background-color: var(--brand-primary-color);

  .authentication-error-body {
    position: relative;
    top: calc(50% - 35px);
    margin: 0 auto;
    max-width: 850px;
    padding: 25px;

    @include mobile {
      top: calc(50% - 75px);
    }

    &.docuSign-body {
      top: calc(50% - 125px);

      @include mobile {
        top: calc(50% - 175px);
      }
    }

    h3 {
      padding-bottom: 15px;
    }

    ol {
      li {
        padding: 5px 0;
        color: var(--urbanx-black);
        font-family: Inter;
        font-style: normal;
        font-weight: 300;
      }
    }
  }
}
