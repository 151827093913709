@import '../../../Mixins.scss';

.tierValueInputs {
  display: grid;
  width: -webkit-fill-available;
  width: -moz-available;

  grid-template-areas:
    'lowerBound lowerBound lowerBound lowerBound'
    'upperBound at percentage commissionRemove';
  grid-template-columns: 2fr 20px 1fr 20px;
  gap: 8px;
  align-items: center;

  @include mobile {
    &.isAndAboveRow {
      grid-template-areas: 'lowerBound at percentage commissionRemove';
    }
  }

  @include desktop {
    grid-template-areas: 'lowerBound upperBound at percentage commissionRemove';
    grid-template-columns: 2fr 2fr 20px 1fr 20px;
  }
}

.commissionLowerBound {
  grid-area: lowerBound;
}

.commissionUpperBound {
  grid-area: upperBound;
}

.commissionAt {
  grid-area: at;
}

.commissionPercentage {
  grid-area: percentage;
}

.commissionRemove {
  grid-area: commissionRemove;
}

.commissionStructure {
  > button:last-of-type {
    align-self: flex-start;
  }
}
