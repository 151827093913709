@import '../../../../../../Mixins.scss';
@import '../../../../../../App.scss';

.propertyCard {
  display: flex;
  width: 800px;
  padding: 16px;
  align-items: center;
  gap: 24px;
  border-radius: 18px;
  background: var(--UrbanX-White, #FFF);
  width: -webkit-fill-available;
  width: -moz-available;

  @include mobile {
    max-width: 335px;
    flex-direction: column;
    gap: 14px;
    padding-bottom: 8px;
  }

  @include tabletPortrait {
    max-width: 440px;
    flex-direction: column;
    gap: 14px;
    padding-bottom: 8px;
  }

  @include tabletLandscape {
    max-width: 440px;
  }

  @include desktop {
    max-width: 800px;
  }

  &-Image {
    align-self: stretch;
    border-radius: 12px;
    background-color: lightgrey 50% cover no-repeat;

    @include mobile {
      height: 160px;
      flex-shrink: 1;
    }
  
    @include tabletPortrait {
      max-width: 440px;
      height: 222px;
      flex-shrink: 1;
    }
  
    @include tabletLandscape {
      max-width: 440px;
      height: 226px;
      flex-shrink: 1;
    }
  
    @include desktop {
      max-width: 800px;
      width: 226px;
      flex-shrink: 0;
    }
  }

  &-Details {
    justify-content: space-between;
    align-items: flex-start;
  }

  &-Title {
    > h4 {
      color: var(--UrbanX-Black, #000);
      font-family: Inter;
      font-size: 24px;
      font-style: normal;
      font-weight: 300;
      line-height: 130%;
      letter-spacing: 0.01px !important;
    }
  }

  .propertyImageLabel {
    color: var(--Neutral-Grey-600, #989898);
    font-family: Inter;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;
    letter-spacing: 0.06px;
  }

  &:hover {
    cursor: pointer;
    background: rgba(245, 245, 245, 1);

    svg,
    label {
      cursor: pointer;
    }

    .propertyConfirmBtn {
      background: linear-gradient(180deg,
          var(--brand-secondary-color-alpha90) 0,
          var(--brand-secondary-color) 72.9%) var(--brand-secondary-color);
      border: none;
      color: var(--brand-text-button-color);

      >svg {
        fill: var(--brand-text-button-color);
      }
    }
  }
}

.propertyImageDetail {
  font-weight: 400;
  word-wrap: none;
  white-space: nowrap;
}

.mappedDetailsRowSpacing {
  gap: 16px;

  &.propertySpecs {
    gap: 16px;
    border-bottom: 1px solid var(--Neutral-Grey300, #DFDFDF);
    padding: 16px 0 16px 0;

    @include mobile {
      align-items: flex-start;
      align-content: flex-start;
      gap: 8px 16px;
      align-self: stretch;
      flex-wrap: wrap;
    }
  }

  &.propertyNumbers {
    align-items: flex-start;
    gap: 24px;
    padding: 16px 0 16px 0;
    align-self: stretch;

    @include mobile {
      align-items: center;
    }

    .propertyNumber {
      padding-right: 24px;
      align-items: flex-start;
    }
  }

  &.propertyOwners {
    gap: 8px;

    @include mobile {
      align-items: center;
      align-content: center;
      gap: 2px 8px;
      align-self: stretch;
      flex-wrap: wrap;
    }

    .propertyOwner {
      padding-right: 8px;
    }
  }

  .propertyItem {
    border-right-color: var(--Neutral-Grey400, #CDCDCD);
    border-right-style: solid;
    border-right-width: 1px;

    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      padding-right: 0;
      border-right: none;
    }

    span {
      color: var(--UrbanX-Black, #000);
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 125%;
      letter-spacing: 0.06px;
    }

  }
}