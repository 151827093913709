@import '../../../Mixins.scss';

.propertyImageContainer {
  padding: 0 12px;
}

.propertyImage {
  border-radius: 12px;
  max-width: 100%;
}

.signingGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content;
  border-top: 1px solid var(--neutral-grey-300);
  margin: 0 -40px -32px -40px;

  @include tabletPortrait {
    margin: 0 -20px -32px -20px;
  }

  @include mobile {
    grid-template-columns: 1fr;
    margin: 0 -20px;
  }

  .signingGridCell {
    padding: 24px 20px;

    &:first-of-type {
      border-right: 1px solid var(--neutral-grey-300);
    }

    @include mobile {
      border-right: none;
      border-bottom: 1px solid var(--neutral-grey-300);
    }
  }
}

.toastStyle {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px;
  gap: 10px;

  width: 290px;
  height: 95px;

  background: #fafafa;

  border: 1px solid #dfdfdf;

  box-shadow:
    0px 4px 8px rgba(0, 0, 0, 0.05),
    0px 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 16px;

  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  z-index: 3;
}
