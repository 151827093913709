@import '../../../Mixins.scss';

.selectedProperties {
  gap: 32px;

  .button {
    align-self: baseline;
  }
}

.searchNowProperty {
  display: grid;

  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    'address price'
    'details details';

  @include mobile {
    grid-template-columns: 1fr;
    grid-template-areas:
      'address'
      'price'
      'details';
  }

  width: 100%;
  align-items: flex-end;
  gap: 10px;

  .propertyLabel {
    grid-area: label;
  }

  .distanceLabel {
    grid-area: distance;
  }

  .addressInput {
    grid-area: address;
  }

  .priceInput {
    grid-area: price;
  }

  .details {
    grid-area: details;
    align-items: flex-end;

    svg {
      fill: var(--neutral-grey-700);

      path {
        fill: var(--neutral-grey-700);
      }
    }
  }
}
