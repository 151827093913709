@import '../../../../Mixins.scss';

.wrap-collapsible {
  max-height: 0px;
  z-index: 1;
  transition: max-height 0.25s ease-in-out;

  @include mobile {
    position: fixed;
    width: 100%;
    top: 62px;
    left: 0;
    height: calc(100vh - 62px);
    z-index: 10;

    @include mobile {
      height: calc(100vh - 132px);
    }
  }
}

.toggle:checked + .lbl-toggle + .wrap-collapsible {
  max-height: calc(100vh - 62px);

  @include mobile {
    max-height: calc(100vh - 132px);
  }
}

.filters {
  padding: 12px 15px;
  background: #ffffff;
}

.filterStyle {
  display: flex;
  outline: 2px solid var(--neutral-grey-300);
  border-radius: 5px;
  padding: 7px;
  width: 90%;
}
.filterTextStyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0;
  width: 90%;
}

.filterActionButtons {
  @include mobile {
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    width: calc(100vw - 20px);
    padding: 10px;
  }
}

.filters-opened {
  &::after {
    @include mobile {
      content: ' ';
      position: fixed;
      width: 100vw;
      height: calc(100vh - 62px);
      left: 0;
      top: 62px;
      background-color: var(--neutral-grey-200);
      z-index: 3;
    }
  }
}

.collapsible-content .content-inner {
  background-color: var(--neutral-grey-200);
  padding: 20px;
  border-bottom: 1px solid #dfdfdf;

  @include desktop {
    max-height: calc(100vh - 115px);
  }

  @include mobile {
    padding-bottom: 300px;
  }
  @include tabletPortrait {
    padding-bottom: 300px;
  }
}

.collapsible-content p {
  margin-bottom: 0;
}
