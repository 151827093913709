@import '../../../../../../Mixins.scss';

.agencyAgreementSigningPanelButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 10px;

  @include mobile {
    flex-direction: column;
    justify-content: center;
    gap: 10px;
  }

  @include tabletPortrait {
    flex-wrap: wrap;
  }

  .campaignModalButtons {
    @include mobile {
      position: fixed;
      width: -webkit-fill-available;
      margin: 0;
    }
  }
}

.AgreementPropertyImage {
  border-radius: 10px;

  @include mobile {
    width: 200px;
    height: 100px;
  }

  @include desktop {
    width: 320px;
    height: 160px;
  }

  @include tabletLandscape {
    width: 200px;
    height: 100px;
  }

  @include tabletPortrait {
    width: 200px;
    height: 100px;
  }

  aspect-ratio: 2 / 1;
}
