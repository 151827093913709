@import '../../../Mixins.scss';

.clauseTabs {
  width: -webkit-fill-available;
  width: -moz-available;
  background: var(--neutral-grey-100);
  margin-left: -19.2px;
  margin-right: -19.2px;
}

.specialClauseTab {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 20px;
  height: 44px;
  border-bottom: 1px solid var(--neutral-grey-400);
}

.specialClauseTab.selected {
  font-weight: 500;
  color: var(--urbanx-black);
  border-bottom: 2px solid var(--urbanx-black);
}

.specialClauseContainer {
  padding: 32px 40px;
  border-bottom: 1px solid var(--neutral-grey-300);

  @include mobile {
    padding: 24px 20px;
  }
}

.specialClauseBody {
  overflow-y: auto;
  max-height: unset !important;
  margin-bottom: -2px;
}

.inputCursorDefault {
  .wrapper {
    input {
      cursor: default;
    }
    width: 227px;
  }
}

.paddingLeft32 {
  padding-left: 32px;
}
