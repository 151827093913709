@import '../../../Mixins.scss';

.AgxCard {
  padding: 28px;
  align-items: flex-start;
  min-height: 144px;
  border-radius: 20px;
  background: var(--urban-x-white, #fff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);

  &.largeGap {
    gap: 32px;
  }

  @include mobile {
    padding: 24px;
    min-height: 80px;
  }

  @include tabletPortrait {
    display: flex;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
  }

  @include tabletLandscape {
    padding: 24px;
  }
}

.card-text {
  color: var(--neutral-grey-800);

  &.sales {
    text-align: right;
  }

  &.name {
    flex-grow: 1;
  }

  &.rank {
    width: 20px;
  }
}
