@import '../../../Mixins.scss';

.targetText {
  &.below {
    color: var(--UI-Error, #f94a3f);
  }

  &.above {
    color: var(--UI-Success, #47ba00);
  }
}

.wideGoal {
  gap: 3px;

  > .targetText {
    font-weight: 600;
  }
}

.statRow.row {
  align-items: end;

  @include tabletLandscape {
    flex-direction: column;
    gap: 4px;
    align-items: baseline;
  }

  .targetSection {
    align-items: baseline;

    @include tabletLandscape {
      flex-direction: row;
      align-items: center;
      gap: 4px;

      > .row {
        align-items: center;
      }
    }

    > .targetText {
      font-weight: 500;
      white-space: pre;
    }
  }
}
