.footerPoweredBy {
  font-family: 'Inter';
  font-style: normal;
  font-size: 12px;
  line-height: 160%;
  letter-spacing: 0.06px;
  color: var(--brand-text-primary-color);

  svg {
    fill: var(--brand-text-primary-color);
  }
}
