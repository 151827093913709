@import '../../../Mixins.scss';

.desktopCmaResultsGrid {
  display: grid;

  grid-template-columns: 1fr 5fr;
  grid-template-areas:
    'results map'
    'results mapActions';
  grid-template-rows: auto 80px;
  width: 100vw;
  height: 100vh;

  .cmaResults {
    grid-area: results;
    height: 100%;
    overflow: hidden;
    filter: drop-shadow(1px 0px 16px rgba(0, 0, 0, 0.17));

    .resultsPanel {
      padding: 20px;
      background: var(--neutral-grey-200);
      max-height: 100%;
      overflow-y: scroll;
      z-index: 1;

      @include desktop {
        height: 100%;
      }
    }

    width: 445px !important;

    @include mobile {
      width: 100vw;
    }
  }

  .cmaMap {
    grid-area: map;
  }

  .mapActions {
    grid-area: mapActions;
    background: #ffffff;
    box-shadow:
      0px 4px 4px rgba(0, 0, 0, 0.25),
      0px -3px 16px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(15px);
    padding: 20px;

    @include tabletPortrait {
      position: sticky;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
    }
  }
}

.cmaResultsCloseButton {
  position: absolute;
  top: 60px;
  right: 60px;
  z-index: 1;

  border-radius: 100%;
  background: var(--brand-secondary-color);

  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  > svg {
    fill: var(--brand-primary-color);
  }
}

.mobileMap {
  margin-bottom: -18px;
}

.mobileResultsInnerContent {
  @include mobile {
    background: #ffffff;
    padding: 0 20px;
    border-radius: 18px 18px 0 0;
    padding-bottom: 500px;
  }
}
.cmaResultsHeader {
  @include mobile {
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

.mobileResults {
  background: #ffffff;
  z-index: 2;
  padding-bottom: 300px;
  width: 100%;

  @include mobile {
    padding-bottom: 130px;
    position: absolute;
    background: transparent;
    top: 60px;
    padding-top: 240px;
    height: 100%;
  }

  .cmaMobileLoadMore .agx-button {
    @include mobile {
      margin-top: 12px;
      align-self: center;
    }
  }
}

.mobileActionButtons {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;

  box-shadow:
    0px -4px 8px rgba(0, 0, 0, 0.05),
    0px -1px 2px rgba(0, 0, 0, 0.1);
  padding: 12px;
  z-index: 3;

  @include mobile {
    background-color: #fff;
  }

  .label {
    width: -webkit-fill-available;
    width: -moz-available;
  }
}

#collapsible {
  display: none;
}

.lbl-toggle {
  display: block;
  text-align: center;
  transition: all 0.25s ease-out;
}

.toggle:checked + .lbl-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
