@import 'Mixins.scss';

/* Loading indicator */
.propertySearchLoader {
  width: 100px;
  height: 100px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
  border-top: 5px solid #555;
  border-radius: 50%;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.agx-noForm {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 18px;
  width: -webkit-fill-available;
  width: -moz-available;

  @include mobile {
    padding: 24px 20px;
  }

  @include tabletPortrait {
    padding: 24px 20px;
  }

  @include desktop {
    gap: 24px;
    padding: 32px 40px;
  }
}

.formContent.formContentPropertySearch {
  padding: 0;

  @include desktop {
    padding-top: calc(50vh - 135px);
  }

  @include mobile {
    padding-top: calc(50vh - 130px);
  }

  @include tabletPortrait {
    padding-top: calc(50vh - 135px);
  }

  @include tabletLandscape {
    padding-top: calc(50vh - 135px);
  }
}

.formContent.formContentPropertySearch:has(.agx-propertyConfirmation) {
  padding-bottom: 0;
  padding-top: 0;

  @include tabletLandscape {
    padding-top: 50px;
  }
}

.agxFormPage {
  &.noBreadcrumbs:has(.formContentPropertySearch) {
    @include desktop {
      align-items: normal;
    }
  }
}
