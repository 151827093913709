@import 'Mixins.scss';

.formContent.formContentPropertySearch:has(
    .propertySearchAutoCompleteContainer
  ) {
  padding: 0;

  @include desktop {
    padding-top: calc(35vh - 135px);
  }

  @include mobile {
    padding-top: calc(35vh - 130px);
  }

  @include tabletPortrait {
    padding-top: calc(35vh - 135px);
  }

  @include tabletLandscape {
    padding-top: calc(35vh - 135px);
  }
}

.property-search-results {
  background-color: var(--brand-primary-color);
  padding: 4px;
  gap: 24px;
  z-index: 1;
  min-width: 320px;
  width: 100%;
  width: -webkit-fill-available;
}

.property-search-result {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 8px;
  border-radius: 8px;
  gap: 6px;
  width: -webkit-fill-available;
  width: -moz-available;

  svg {
    fill: var(--brand-secondary-color);
  }
}

.property-search-detail {
  padding: 0px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.property-search-result:hover {
  cursor: pointer;
}

.property-search-result:hover,
.property-search-result.focused {
  background-color: var(--brand-sidebar-background-color);
}

.property-search-result-detail > .propertyAddress {
  color: var(--brand-text-primary-color);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 135%;
  letter-spacing: 0.06px;
  margin: 0;
}

.property-search-result-detail > .propertyRegion {
  color: var(--brand-text-primary-color);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 125%;
  letter-spacing: 0.06px;
  opacity: 0.8;
  margin: 0;
}

.tabsContainer {
  width: -webkit-fill-available;
  width: -moz-available;
}

.tabSelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 5px;

  border-bottom: 1px solid transparent;

  > span.body {
    color: var(--brand-text-primary-color);
    opacity: 0.5;
  }

  &.selected {
    border-bottom: 1px solid var(--brand-text-primary-color);

    > span.body {
      opacity: 1;
    }
  }

  &:hover:not(.selected) {
    border-bottom: 1px solid var(--brand-text-primary-color);

    > span.body {
      opacity: 0.75;
    }
  }
}
