@import '../Mixins.scss';

.brandedLayout {
  gap: 24px;

  @include desktop {
    padding: 24px;
  }

  @include tabletLandscape {
    gap: 0px;
    padding: 20px;
  }

  @include tabletPortrait {
    gap: 0px;
    padding: 16px;
  }

  @include mobile {
    padding: 20px 0 0 0;
    height: 100%;
  }

  .dashboardHeader-card {
    gap: 16px;
  }
}

.brandedHeader {
  @include mobile {
    padding: 0 20px;
  }
}

.headerLogo {
  grid-area: headerLogo;
  align-self: flex-start;

  @include desktop {
    height: 56px;
  }

  @include mobile {
    height: 36px;
    max-width: 200px;
  }

  @include tabletLandscape {
    height: 40px;
    max-width: 200px;
  }

  @include tabletPortrait {
    height: 40px;
    max-width: 200px;
  }
}
