@import '../../../Mixins.scss';

.campaignsViewPage {
  display: grid;
  gap: 24px;
  background: var(--brand-primary-color);

  --leaderboard-height: 300px;
  --leaderboard-gap: 20px;
  --leaderboards-num: 2;
  --total-sidebar-height: calc(
    (var(--leaderboard-height) * var(--leaderboards-num)) +
      ((var(--leaderboards-num) - 1) * var(--leaderboard-gap))
  );

  @include mobile {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'title title'
      'campaigns campaigns';
    grid-template-rows: min-content min-content min-content;
  }

  @include desktop {
    grid-template-columns: 4fr 1fr;
    grid-template-areas:
      'title .'
      'campaigns team';
    grid-template-rows: auto;
  }

  @include tabletLandscape {
    gap: 0px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'campaigns';
    grid-template-rows: auto;
  }

  @include tabletPortrait {
    gap: 0px;
    grid-template-columns: 1fr;
    grid-template-areas:
      'title'
      'campaigns';
    grid-template-rows: auto;
  }
}

.headerOptions {
  grid-area: headerOptions;

  @include mobile {
    padding-right: 20px;
  }
}

.dashboardTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  width: -webkit-fill-available;
  width: -moz-available;
  grid-area: title;

  @include desktop {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  @include mobile {
    padding: 0px 20px 10px;
  }

  @include tabletLandscape {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }

  @include tabletPortrait {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 0;
  }
}

.dashboardMain {
  display: grid;
  gap: 20px;
  background: var(--brand-inbox-background-color);
  flex-grow: 1;
  grid-area: campaigns;
  overflow: hidden;

  @include desktop {
    padding: 20px;
    grid-template-columns: minmax(400px, 400px) minmax(400px, 100%);
    border-radius: 18px;
    max-height: calc(
      100vh - 254px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    max-height: calc(100dvh - 254px);
    min-height: var(--total-sidebar-height);
  }

  @include tabletLandscape {
    padding: 16px;
    grid-template-columns: minmax(352px, 352px) minmax(352px, 1299px);
    border-radius: 18px;
    min-height: calc(
      100vh - 200px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    max-height: calc(
      100vh - 200px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    min-height: calc(100dvh - 200px);
    max-height: calc(100dvh - 200px);
  }

  @include tabletPortrait {
    padding: 16px;
    grid-template-columns: minmax(300px, 300px) minmax(300px, 919px);
    border-radius: 18px;
    min-height: calc(
      100vh - 200px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    max-height: calc(
      100vh - 200px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    min-height: calc(100dvh - 200px);
    max-height: calc(100dvh - 200px);
  }

  @include mobile {
    border-radius: 18px 18px 0 0;
    padding: 20px;
    min-height: calc(
      100vh - 248px - env(safe-area-inset-bottom) - env(safe-area-inset-top)
    );
    min-height: calc(100dvh - 248px);
  }
}

.dashboardBody {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 24px;
  height: -webkit-fill-available;
  height: -moz-available;
  width: -webkit-fill-available;
  width: -moz-available;
}

.bodySectionSideBarContainer {
  grid-area: team;

  & > .column {
    height: 100%;
  }

  .columnFill {
    flex-grow: 1;
  }

  .sidebarLeaderboard {
    max-height: var(--leaderboard-height);
    padding: 10px var(--leaderboard-gap);
    border-radius: 20px;
    background: var(--brand-sidebar-background-color);
    margin-top: 20px;

    &.first-leaderboard {
      margin-top: 0;
    }

    .header-text {
      color: var(--brand-text-primary-color);
      padding-bottom: 12px;
    }

    .leaderboard-row {
      display: flex;
      padding: 10px 15px;
      justify-content: flex-end;
      align-items: center;
      gap: 4px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .highlighted {
      background: var(--brand-secondary-color);
      color: var(--brand-text-button-color);
      border-radius: 20px;
      span {
        color: var(--brand-text-button-color);
      }
    }

    .leaderboard-rowText {
      color: var(--brand-text-primary-color);
    }

    .rank {
      width: 20px;
    }

    .image-agent {
      gap: 20px;
    }

    @include desktop {
      max-width: 330px;
      width: 330px;
    }
  }

  .footer {
    margin-top: auto;
  }
}
