.agxFormGenerationPage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  min-height: 100vh;
  background: var(--brand-primary-color);

  > svg {
    max-width: 400px;

    > .agx-settings-icon-primary {
      stroke: #ffffff;
    }

    > .agx-settings-icon-secondary {
      stroke: var(--brand-secondary-color);
    }
  }

  .headerSubText {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    letter-spacing: 0.06px;
    color: var(--brand-secondary-color);
  }
}
