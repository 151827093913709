@import '../../../../../../../Mixins.scss';

.contractRequestContracts {
  justify-content: start;
  flex: 1;
  position: relative;

  .downloadBlankContractWrapper {
    padding: 12px 0;
  }
}