@import '../../../../../Mixins.scss';
.dashboardInbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 18px;
  width: -webkit-fill-available;
  width: -moz-available;
  overflow: hidden;
  height: 100%;

  @include mobile {
    max-width: 400px;
  }

  @include tabletLandscape {
    max-width: 352px;
  }

  @include tabletPortrait {
    max-width: 300px;
  }

  > .column.scrollable {
    @include desktop {
      padding-right: 10px;
    }
  }
}

.propertySelectedInfoCard > .header {
  width: -webkit-fill-available;
  width: -moz-available;
}

@include mobile {
  .propertySelectedInfoCard,
  .bodySectionSideBarContainer {
    display: none;
  }

  .campaignsTitle {
    font-weight: 500;
    font-size: 14px;
  }
}

@include tabletLandscape {
  .propertySelectedInfoCard,
  .bodySectionSideBarContainer {
    display: none;
  }

  .campaignsTitle {
    font-weight: 500;
    font-size: 14px;
  }
}

@include tabletPortrait {
  .propertySelectedInfoCard,
  .bodySectionSideBarContainer {
    display: none;
  }

  .campaignsTitle {
    font-weight: 500;
    font-size: 14px;
  }
}

@include desktop {
  .propertySelectedInfoCard {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 28px;
    gap: 24px;
    background: #ffffff;
    border-radius: 18px;
    overflow-y: scroll;
    padding-right: 8px;
  }

  .campaignsTitle {
    font-weight: 500;
    font-size: 16px;
  }

  .staffList {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 20px 16px;
    gap: 12px;
    background: var(--brand-sidebar-background-color);
    border-radius: 20px;
    width: -webkit-fill-available;
    width: -moz-available;
    h4,
    .descriptionText {
      color: var(--brand-text-primary-color);
      line-height: 1.45;
      .contactUs {
        color: var(--brand-text-primary-color);
        text-decoration: underline;
        font-weight: bold;
      }
    }
    @include desktop {
      max-width: 330px;
      width: 330px;
    }
  }

  .staffDetails {
    padding: 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 16px;
    width: -webkit-fill-available;
    width: -moz-available;
  }

  .propertyViewUserInfo {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 2px;
  }

  .propertyViewUserInfo > a {
    opacity: 0.65;
    text-decoration: none;
  }

  .propertyViewUserDept {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.06px;
    color: var(--brand-text-primary-color);
    opacity: 0.65;
  }
}
// /* scrollbar */
::-webkit-scrollbar {
  width: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
