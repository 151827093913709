@import '../../../../../Mixins.scss';

.noPropertyFound {
  @include desktop {
    max-width: 570px;
  }

  @include mobile {
    height: 90vh;
  }
}
