@import '../../../../../../../Mixins.scss';

.sentForSigningButtons {
  display: flex;
  flex-direction: row;
}

.confirmationModalStyle {
  padding: 16px 40px;
}

.confirmationButtonContainerStyle {
  display: flex;
  flex-direction: row;
  gap: 18px;
  @include mobile {
    flex-direction: column;
  }
}

.agencyAgreementSigningPanelButtons {
  .multiOptionButton .multiOptionButton-options {
    width: 220px;

    @include mobile {
      z-index: 1100;
    }
  }
}
