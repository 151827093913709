@import '../../../Mixins.scss';

.marketingPackageItems {
  padding: 32px 0 0;

  .marketingPackageItemList {
    padding: 0px;

    .marketingPackageItem {
      min-height: 48px;
      padding: 2px 0;

      &.custom {
        padding: 4px 0;

        &.error {
          align-items: flex-start;
        }

        .customItemLabel {
          .wrapper {
            padding: 5px 8px;
          }
        }
      }

      &:not(:nth-last-child(2)) {
        border-bottom: 1px solid var(--neutral-grey-300);
      }

      > .agxControlledCheckbox {
        > input[type='checkbox'] {
          min-width: 20px;
        }
      }
    }

    .marketingPackageSubtotal {
      padding: 16px 0 40px 0;
      border-top: 2px solid var(--neutral-grey-700);

      .subTotalTitle {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 125%; /* 17.5px */
        letter-spacing: 0.06px;
        color: var(--urbanx-black);
      }

      .subTotalValue {
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        
      }
    }
  }

  .marketingPackageTotal {
    padding: 24px 0 24px 0;
    border-top: 2px solid var(--neutral-grey-700);

    .totalTitle {
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 125%; /* 17.5px */
      letter-spacing: 0.06px;
      color: var(--urbanx-black);
    }

    .totalValue {
      font-family: Inter;
      font-size: 20px;
      font-weight: 600;
    }
  }
}
