@import '../../../Mixins.scss';

.desktopSidebar {
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 30px;
  top: 96px;
  max-height: calc(100vh - 126px);
  max-width: 500px;

  @include tabletLandscape {
    width: 260px;
  }

  @include mobile {
    display: none !important;
  }

  @include tabletPortrait {
    display: none !important;
  }
}

.sidebarHeader {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-bottom: 16px;
}

.sidebarContent {
  flex-grow: 1;
  overflow-y: auto;
}

.pageRow {
  padding: 8px 0px;
  cursor: pointer;

  &.Future {
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  &.FutureDisabled {
    cursor: default;
    opacity: 0.5;

    &:hover {
      opacity: 0.5;
    }
  }
}

.pageListColumnStyle {
  @include desktop {
    gap: 16px;
  }

  @include tabletLandscape {
    gap: 8px;
  }
}

.pageListTextStyle {
  &.Complete {
    color: var(--brand-secondary-color);
  }

  @include desktop {
    font-size: 16px;
  }

  @include tabletLandscape {
    font-size: 14px;
  }
}

.tabletHeadingStyle {
  font-size: 24px !important;
}
