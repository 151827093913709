@import '../../../Mixins.scss';

.deposit {
  .agx-select {
    width: 25%;
    gap: 8px;

    @include mobile {
      width: 40%;
    }
  }

  .agx-error-label {
    color: var(--ui-error);
  }
}
