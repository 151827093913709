.agx-conjunctionFee {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.agx-conjunctionFee-inputs {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  width: -webkit-fill-available;
  width: -moz-available;
}

.agx-conjunctionFee-inputs > .agx-select {
  flex: 2;
}

.agx-conjunctionFee-inputs > .agx-input {
  flex: 1;
}
