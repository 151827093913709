.icon-bubble {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--brand-secondary-color, #cfa987);
  cursor: pointer;

  > svg {
    width: 24px;
    height: 24px;

    fill: var(--brand-primary-color, #ffffff);

    > path {
      fill: var(--brand-primary-color, #ffffff);
    }
  }
}
