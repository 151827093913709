.contributionsInfo {
  > svg {
    min-width: 24px;
  }
}

.upfrontPayment.error {
  color: var(--ui-error);
}

.agentContributionCurrency {
  width: 120px;
}

.marketingContributions.total {
  .totalTitle {
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 125%;
    letter-spacing: 0.06px;
    color: var(--urbanx-black);
  }

  .totalValue {
    font-family: Inter;
    font-size: 20px;
    font-weight: 600;
  }
}
