@import '../../../Mixins.scss';

.reportLeaderboard {
  grid-area: leaderboard;
  gap: 25px;

  @include tabletPortrait {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-self: stretch;
  }

  .image-agent {
    gap: 20px;
  }

  .leaderboardGrid {
    border-radius: 16px;
    background: var(--UrbanX-White, #fff);
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3);

    @include mobile {
      display: flex;
      flex-direction: column;
      align-self: stretch;
    }

    .card-header {
      padding: 16px 18px;

      > .body {
        font-weight: 400;
      }
    }

    .leaderboard-row:last-child.leaderboard-row-highlighted {
      padding-bottom: 20px;
    }

    .leaderboard-row {
      display: flex;
      padding: 12px;
      gap: 8px;
      border-top: 1px solid var(--transparent-10-black, rgba(0, 0, 0, 0.1));

      @include desktop {
        padding: 12px 18px;
      }

      .leaderboard-rowText.rank {
        min-width: 18px;
      }

      &.highlighted {
        position: relative;
        display: flex;
        padding-bottom: 10px;
        border: 0px 0px 1px 0px;

        &:last-child {
          margin-bottom: 12px;
        }

        .leaderboard-rowText {
          color: var(--brand-text-button-color);
          position: relative;
          z-index: 1;
        }

        .image-agent {
          gap: 20px;
          z-index: 1;
        }

        &:not(:first-child) {
          border-top: none;
        }

        & + .leaderboard-row {
          border-top: none;
        }

        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: var(--brand-secondary-color);
          z-index: 0;
          border-radius: 16px;
          gap: 8px;

          margin: 0px 4px;

          @include desktop {
            margin: 0px 8px;
          }
        }
      }
    }
  }

  .leaderboard-row-hidden {
    padding: 12px 18px;
    border-spacing: 0px;
    border-radius: 16px;
    background-color: var(--brand-secondary-color);

    .leaderboard-row-hidden-text {
      &.body {
        color: var(--brand-text-button-color);
      }

      &.sales {
        text-align: right;
      }

      &.name {
        flex-grow: 1;
      }
    }
  }
}
