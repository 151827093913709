@import 'Mixins.scss';

.manualAddressContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.formContent.formContentPropertySearch:has(.manualAddressContainer) {
  padding: 0;

  @include desktop {
    padding-top: calc(35vh - 135px);
  }

  @include mobile {
    padding-top: calc(35vh - 130px);
  }

  @include tabletPortrait {
    padding-top: calc(35vh - 135px);
  }

  @include tabletLandscape {
    padding-top: calc(35vh - 135px);
  }
}

.manualAddressContainer .textInput.error .wrapper.error {
  background: rgb(255, 254, 254);
}

.enterAddressManuallyLink {
  font-family: Inter;
  font-style: normal;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.06px;
  line-height: 165%;
  color: var(--brand-text-primary-color);
  text-decoration: underline;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.manualAddressFormNextBtn {
  align-self: flex-start !important;
}
