@import '../../../App.scss';
@import '../../../Mixins.scss';

.segmentedPicker {
  padding: 2px;
  background: var(--neutral-grey-300);
  border-radius: 8.91px;

  justify-content: space-evenly;
  width: -webkit-fill-available;
  width: -moz-available;

  .segmentPicker {
    width: -webkit-fill-available;
    width: -moz-available;

    @include desktop {
      .mobileTitle {
        display: none !important;
      }
    }

    @include mobile {
      .title {
        display: none !important;
      }
    }

    @include tabletPortrait {
      .title {
        display: none !important;
      }
    }

    @include tabletLandscape {
      .title {
        display: none !important;
      }
    }

    text-align: center;
    padding: 4px;

    &.selected {
      background: #ffffff;
      border-radius: 8.91px;
    }
  }
}
