.agxCircularSelector {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.agxCircularSelector-circle {
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  border: 1px solid #b9b9b9;
  border-radius: 50%;
}

.agxCircularSelector-circle.selected {
  fill: var(--brand-text-button-color);
  background: var(--brand-secondary-color);
  border: 1px solid var(--brand-secondary-color);
}
