.contractDeposit {
  .agx-select {
    gap: 0px !important;
    width: 160px;
  }

  .header,
  .agxNumberHeader {
    display: none;
  }

  .wrapper,
  .agxNumberWrapper {
    margin-top: 21px;
    height: 20px;
  }
}
