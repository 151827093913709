@import '../../../../../Mixins.scss';

.propertyCardDetails {
  @include tabletPortrait {
    width: 190px !important;
  }
}

.propertyViewImageCard {
  padding: 12px;
  border-radius: 16px;

  &.selected {
    background: #ffffff;
  }

  & > img {
    width: 90px;
    height: 68px;
    border-radius: 12px;
    &.archived {
      filter: grayscale(100%);
    }
  }

  @include mobile {
    background: #ffffff;
    padding: 10px;

    & > img {
      width: 76px;
      height: 58px;
      &.archived {
        filter: grayscale(100%);
      }
    }
  }
  &.unread .date {
    color: #000000 !important;
    font-weight: bold;
  }

  &.unread .circle {
    width: 9px;
    height: 9px;
    background-color: #000000;
    border-radius: 50%;
    display: inline-flex;
    margin-left: 5px;
  }

  @include tabletLandscape {
    background: #ffffff;
    padding: 10px;

    & > img {
      width: 76px;
      height: 58px;
      &.archived {
        filter: grayscale(100%);
      }
    }
  }

  @include tabletPortrait {
    background: #ffffff;
    padding: 10px;

    & > img {
      width: 76px;
      height: 58px;
      &.archived {
        filter: grayscale(100%);
      }
    }
  }
}

.gapOther {
  gap: 12px;
}

.campaignListAddress {
  font-weight: 300;

  @include desktop {
    font-size: 18px;
  }

  @include tabletLandscape {
    font-size: 16px;
  }

  @include tabletPortrait {
    font-size: 16px;
  }

  @include mobile {
    font-size: 14px;
  }
}

.propertyViewImageCard:hover {
  background-color: #ffffff;
  transition: background-color 0.2s ease;
}

.lightText {
  color: rgba($color: #000000, $alpha: 0.5) !important;
}

.boldText {
  color: #000000 !important;
  font-weight: bold !important;
}
