@import '../../../Mixins.scss';

.marketingPackageSelector {
  padding: 0 0 32px 0;
}

.agentToFillCurrency {
  width: 105px;

  &.error {
    padding: 6px 0;
  }
}

.customItemLabel {
  &.error {
    padding-bottom: 0;
  }

  .error {
    padding: 5px 8px;
  }
}

.customItemPrice {
  width: 140px;

  @include mobile {
    width: 220px;
  }
}

.marketingPackageItem {
  .marketingItemDeleteBtn {
    margin-right: -24px;
  }
}
