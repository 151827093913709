.agx-error-label > svg {
  min-width: 24px;
}

.flexGrow1 {
  flex-grow: 1;
}

.paddingTop20 {
  padding-top: 20px;
}

.depositPayableTextInput {
  width: 95px;
}
