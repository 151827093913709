@import '../../../../../Mixins.scss';

.propertySearchLoaderContent {
  flex-grow: 1;
  gap: 24px;

  @include mobile {
    gap: 0px;
    justify-content: center;
  }

  .searchLoaderAnimation {
    @include mobile {
      width: 280px;
      height: 280px;
    }
  }

  .propertySearchDetailText {
    gap: 24px;

    @include mobile {
      gap: 12px;
    }
  }
}

.formContent.formContentPropertySearch:has(.propertySearchLoaderContent) {
  @include desktop {
    padding-top: calc(50vh - 362px);
  }

  @include mobile {
    padding-top: calc(50vh - 238px);
  }

  @include tabletPortrait {
    padding-top: calc(50vh - 312px);
  }

  @include tabletLandscape {
    padding-top: calc(50vh - 312px);
  }
}
