@import '../../../../../../../Mixins.scss';

.contractListingDetails {
  justify-content: start;
  flex: 1;

  @include desktop {
    > div > img {
      max-width: 320px;
    }

    > button {
      min-width: 300px;
    }
  }
}

.listing-imageCard {
  @include desktop {
    width: 370px !important;
    height: 185px !important;
  }

  @include tabletLandscape {
    width: 370px !important;
    height: 185px !important;
  }
  @include tabletPortrait{
    width: 336px !important;
    height: 168px !important;
  }

  padding: 12px;

  background: rgba(255, 255, 255, 0.9);
  border-radius: 16px;
}

.imageCard-image {
  border: 1px solid var(--form-input-border);
  &.archived {
    filter: grayscale(100%);
  }

  @include desktop {
    width: -webkit-fill-available;
    width: -moz-available;
    aspect-ratio: 2 / 1;
  }
}


.image-tag {
  position: relative;
  width: fit-content;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  padding: 8px 10px;
  background: var(--brand-secondary-color);
}