@import '../../../Mixins.scss';

.agx-fullPageOverlay {
  position: fixed;
  display: flex;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: #ffffff;
  z-index: 2;
}

.searchNowFeatures {
  display: grid;
  gap: 8px;

  grid-template-columns: 1fr 2fr;

  @include mobile {
    grid-template-columns: 1fr;
  }
}
