@mixin baseFont {
  font-family: 'Inter';
  font-style: normal;
}

@mixin mobile {
  @media (max-width: 699px) {
    @content;
  }
}

@mixin tabletPortrait {
  @media (min-width: 700px) and (max-width: 919px) {
    @content;
  }
}

@mixin tabletLandscape {
  @media (min-width: 920px) and (max-width: 1299px) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: 1300px) {
    @content;
  }
}
