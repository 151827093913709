@import '../../../Mixins.scss';

.formHeader {
  display: grid;

  @include desktop {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-areas: 'pageTitle saveIndicator titleAction';
    grid-template-rows: min-content;
  }

  @include tabletLandscape {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-areas: 'pageTitle saveIndicator titleAction';
    grid-template-rows: min-content;
  }

  @include tabletPortrait {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'listingDetails listingDetails listingDetails listingDetails optionsBurger'
      'pagesArray pagesArray pagesArray pagesArray pagesArray'
      'pageTitle pageTitle pageTitle pageTitle titleAction';
    grid-template-rows: min-content min-content min-content;
    padding: 0px 20px;
    gap: 16px;
    margin: 0px 32px;
  }

  @include mobile {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'listingDetails listingDetails listingDetails listingDetails optionsBurger'
      'pagesArray pagesArray pagesArray pagesArray pagesArray'
      'pageTitle pageTitle pageTitle pageTitle titleAction';
    grid-template-rows: min-content min-content min-content;
    padding: 0px 20px;
    gap: 16px;
  }
}

svg.rapid {
  width: 24px;
  height: 24px;
  fill: var(--brand-secondary-color);
}

.iconButtonStyle {
  padding: 5px;
  margin: -5px;
  position: absolute;
  right: 20px;
}

.agx-form-header-rapid-text {
  color: var(--brand-secondary-color);

  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;
  letter-spacing: 0.06px;
}

.agx-header-listing-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 7px;
  grid-area: listingDetails;
  width: 100%;
  overflow: hidden;
}

.agx-header-progress {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  grid-area: pagesArray;
}

.agx-progress-item {
  width: 24px;
  height: 24px;
  border-radius: 800px;

  &.Future {
    opacity: 0.5;
  }

  &.Complete {
    background: var(--brand-secondary-color);
  }

  &.Invalid {
    background: var(--ui-error);
  }
}

.agx-progress-item-circle {
  box-sizing: border-box;
  border: 1.5px solid rgba(var(--brand-text-primary-color-rgb), 0.15);
  border-radius: 800px;
  width: 24px;
  height: 24px;

  display: flex;
  justify-content: center;
  align-items: center;

  &.active {
    border: 1.5px solid var(--brand-secondary-color);
  }

  &.Future {
  }

  &.Complete {
    border: 1.5px solid var(--brand-secondary-color);
    background: var(--brand-secondary-color);
  }

  &.Invalid {
    border: 1.5px solid var(--ui-error);
    background: var(--ui-error);

    &:before {
      padding-top: 3px;
      content: url('../../../assets/icons/exclamation.svg');
    }
  }

  &.large {
    width: 40px;
    height: 40px;
  }
}

.agx-progress-item.active > .agx-progress-item-circle {
  border: 1.5px solid var(--brand-secondary-color);
}

.agx-progress-item-text {
  color: var(--brand-text-primary-color);
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;

  &.inverted {
    color: var(--brand-text-button-color);
  }
}

.agx-pageListContainer.isActive .agx-progress-item-text {
  &.inverted {
    color: var(--brand-text-primary-color);
  }
}

.progressCircle.Future .agx-progress-item-text {
  &.inverted {
    color: var(--brand-text-primary-color);
  }
}

.agx-progress-item.Complete .agx-progress-item-text,
.agx-progress-item-circle.Complete .agx-progress-item-text {
  color: var(--brand-text-button-color);
}

.desktopAndTabletLandscape {
  @include mobile {
    display: none !important;
  }

  @include tabletPortrait {
    display: none !important;
  }
}

.mobileAndTabletPortrait {
  @include desktop {
    display: none !important;
  }

  @include tabletLandscape {
    display: none !important;
  }
}

.titleAction {
  @include desktop {
    display: none !important;
  }

  @include tabletLandscape {
    display: none !important;
  }

  grid-area: titleAction;

  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.agx-form-header-exit {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 32px;
  height: 32px;
  border-radius: 100px;
  background: var(--brand-secondary-color);
}

svg.exit {
  fill: var(--brand-primary-color);
}

.agx-pageListContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;

  &.isActive {
    background: var(--neutral-grey-200);
  }

  .progressCircle {
    width: 24px;
    height: 24px;
    border-radius: 800px;
    border: 1.5px solid var(--neutral-grey-300);
    text-align: center;

    &.isActive {
      background: none;
      border: 1.5px solid var(--brand-secondary-color);
    }

    &.Future {
      background: none;
    }

    &.Complete {
      background: var(--brand-secondary-color);
    }

    &.Invalid {
      background: var(--ui-error);
    }
  }

  .listing-detail-line {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
