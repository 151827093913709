@import '../../../Mixins.scss';

.fadeInAnimation {
  animation: fadeInAnimation ease 3s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.redirectButtonStyle {
  color: var(--urban-x-white, #fff);
  background: var(--urban-x-black, #000);
  border: 1px solid var(--urban-x-black, #000);
}

.alignVCenter {
  position: absolute;
  top: calc(50% - 200px);

  @include mobile {
    top: calc(50% - 220px);
  }
}
