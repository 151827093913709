@import '../../../Mixins.scss';

.agxPropertyCard {
  display: grid;
  gap: 12px;
  grid-template-columns: 80px auto 28px;
  background: var(--neutral-grey-100);
  border: 1px solid var(--neutral-grey-300);
  border-radius: 18px;
  padding: 8px;

  align-items: center;

  @include desktop {
    width: 370px;
  }

  &.selected {
    border: 1.5px solid var(--brand-secondary-color);
    background: #ffffff;
    padding: 7.5px;
  }

  .propertyImageWrapper {
    width: 80px;
    height: 90px;
    background-size: cover;
    border-radius: 10px;
    background-position: center center;
  }

  .cmaDistancePrepend {
    @include mobile {
      display: none;
    }
  }
}
