@import '../../../../Mixins.scss';

.pageTitle {
  grid-area: pageTitle;
}

.pageTitleContent {
  display: flex;
  align-items: center;
  gap: 2px;

  @include mobile {
    font-size: 24px;
  }

  @include tabletLandscape {
    font-size: 28px;
  }

  svg {
    padding: 6px;

    path {
      fill: var(--brand-text-primary-color);
    }
  }
}
