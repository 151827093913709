@import '../../../../../../../Mixins.scss';

.blankContractPreparation {
  gap: 16px;
  padding: 16px 0 0;
}

.blankContractPreparationTitle {
  padding: 16px 0;
  gap: 16px;
}

.blankContractPreparationBody {
  @include desktop {
    max-width: 350px;
  }

  .blankContractProgress {
    gap: 10px;
    padding: 12px 0;
    border-top: 1px solid var(--neutral-grey-300);

    .body {
      font-weight: 400;
    }

    &:last-child {
      border-bottom: 1px solid var(--neutral-grey-300);
    }

    .blankContractProgressText {
      width: 100%;
    }

    .blankContractProgressValue {
      &.complete {
        color: var(--ui-success);
      }

      &.pending {
        color: var(--neutral-grey-600);
      }
    }
  }
}
